import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import { API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';
import { FileExcelValid } from 'utils/constant';

const defaultValues = {
  channelName: '',
};

const ImportCustomerDialog = ({ isOpen, channelName, onClose, refetchData }) => {
  const cancelRef = useRef();
  const inputImportRef = useRef();
  const toast = useToast();
  const [error, setError] = useState();
  const [fileSelected, setFileSelected] = useState(null);

  const [{ loading: importLoading }, importCustomerApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ImportCustomer,
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    channelName: yup.string().required('Vui lòng nhập nhóm'),
  });
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(
    () => () => {
      setFileSelected(null);
      reset();
    },
    []
  );

  useEffect(() => {
    reset({ channelName });
  }, [channelName]);

  const onSubmit = values => {
    toast.closeAll();

    if (!fileSelected) {
      setError('Vui lòng chọn file cần import');
      return;
    }

    const formData = new FormData();
    formData.append('channelName', values.channelName);
    formData.append('customerFile', fileSelected);

    importCustomerApi({ data: formData })
      .then(() => {
        refetchData();
        toast({
          title: 'Import khách hàng thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose?.(ModalType.Import);
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Import khách hàng không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleFileSelect = e => {
    setError('');
    if (e?.target?.files?.[0]) {
      const customerFile = e.target.files[0];
      const extensionFile = customerFile?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        setFileSelected(customerFile);
      } else {
        setFileSelected(null);
        setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
      }
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Import);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Import khách hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="channelName" label="Nhóm" isRequired disabled={!!channelName} />
              <Box alignItems="center">
                <Flex alignItems="center">
                  <input type="file" hidden ref={inputImportRef} onChange={handleFileSelect} />
                  <Button
                    variant="primary"
                    maxH="30px"
                    my="10px"
                    onClick={() => {
                      setError('');
                      inputImportRef?.current?.click();
                    }}
                  >
                    Chọn file
                  </Button>
                  <Text pl="10px">{fileSelected?.name}</Text>
                </Flex>
                {!!error && (
                  <Text color={'red.500'} fontSize="14px">
                    {error}
                  </Text>
                )}
              </Box>
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Import);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={importLoading} onClick={handleSubmit(onSubmit)}>
              Import
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ImportCustomerDialog;
