import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  Text,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { defaultPassword, API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';

const ResetPassUserModal = ({ isOpen, userDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');

  const [{ loading }, resetPasswordApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ResetPassUser,
    },
    { manual: true }
  );

  const onResetPassword = async () => {
    resetPasswordApi({ data: { id: userDetail?.id } })
      .then(() => {
        toast({
          title: 'Reset Password User Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        refetchData?.();
        onClose?.(ModalType.ResetPassword);
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Reset Password User Fail',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.ResetPassword);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Thiết lập lại mật khẩu</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex flexDirection={'column'}>
              <Text fontSize="md" color={textColor} fontWeight="bold">
                Bạn có chắc chắn muốn thiết lập lại mật khẩu người dùng không?
              </Text>
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {`Mật khẩu mặc định: ${defaultPassword}`}
              </Text>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.ResetPassword);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={loading} onClick={onResetPassword}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ResetPassUserModal;
