import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Flex,
  FormLabel,
  Text,
  Box,
} from '@chakra-ui/react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { ModalType } from 'utils/constant';
import { TINY_API_KEY } from 'utils/constant';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';

const CampaignDetailModal = ({ isOpen, campaignDetail, onClose }) => {
  const cancelRef = React.useRef();
  const [{ data, loading, error }, getCampaignDetail] = useAxios(
    {
      url: API_ROUTES.CampaignDetail(campaignDetail?._id),
    },
    { manual: true }
  );

  useEffect(() => {
    if (campaignDetail?._id) {
      getCampaignDetail();
    }
  }, [campaignDetail?._id]);

  return (
    <>
      <Modal
        size="4xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Detail);
        }}
        isOpen={isOpen}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="uppercase">Chi tiết chiến dịch</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <FormLabel minW="150px" fontWeight="600">
                Tên chiến dịch:
              </FormLabel>
              <Text>{data?.data?.name}</Text>
            </Flex>
            <Flex>
              <FormLabel minW="150px" fontWeight="600">
                Định danh:
              </FormLabel>
              <Text>{data?.data?.emailContent?.sender}</Text>
            </Flex>
            <Flex>
              <FormLabel minW="150px" fontWeight="600">
                Tiêu đề:
              </FormLabel>
              <Text>{data?.data?.emailContent?.subject}</Text>
            </Flex>
            {data?.data?.channelName && (
              <Flex>
                <FormLabel minW="150px" fontWeight="600">
                  Nhóm:
                </FormLabel>
                <Text>{data?.data?.channelName}</Text>
              </Flex>
            )}
            {data?.data?.customers && (
              <Flex>
                <FormLabel minW="150px" fontWeight="600">
                  Khách hàng:
                </FormLabel>
                <Text>{data?.data?.customers?.map(item => item).join(', ')}</Text>
              </Flex>
            )}
            <Box pt="4">
              <TinyEditor
                apiKey={TINY_API_KEY}
                init={{
                  height: 350,
                  menubar: false,
                  toolbar: false,
                  readonly: true,
                  language: 'vi',
                  elementpath: false,
                  branding: false,
                }}
                value={data?.data?.emailContent?.body}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Detail);
              }}
            >
              Hủy
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CampaignDetailModal;
