import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { initialFilter, API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';
import AddEmailTemplateModal from './components/AddEmailTemplateModal';
import { mappingTemplate } from 'utils/mapping';
import Row from './components/Row';
import Pagination from 'components/Pagination/Pagination';
import PreviewTemplateModal from './components/PreviewTemplateModal';
import DeleteTemplateModal from './components/DeleteTemplateModal';
import InputSearch from 'components/InputSearch/InputSearch';

function EmailTemplate() {
  const [filter, setFilter] = useState({ ...initialFilter });
  const [templates, setTemplates] = useState([]);
  const [templateDetail, setTemplateDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenPreviewModal, onOpen: onOpenPreviewModal, onClose: onClosePreviewModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.Preview]: onOpenPreviewModal,
    }),
    [onOpenAddModal, onOpenDeleteModal, onOpenPreviewModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.Preview]: onClosePreviewModal,
    }),
    [onCloseAddModal, onCloseDeleteModal, onClosePreviewModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.EmailTemplate,
      params: filter,
    },
    { manual: true }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateTemplate = (templateDetail, modalType) => {
    setTemplateDetail(templateDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setTemplateDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        setTemplates(mappingTemplate(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Email Template
            </Text>
            <Flex pt="22px" justifyContent="space-between">
              <Flex flex="1">
                <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
              </Flex>
              <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                Thêm
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th borderColor={borderColor}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {templates?.map((row, index, arr) => (
                    <Row
                      key={index}
                      template={row}
                      isLast={index === arr.length - 1 ? true : false}
                      handelUpdateTemplate={handelUpdateTemplate}
                    />
                  ))}
                  {isEmpty(templates) && !loading && (
                    <Tr>
                      <Td colSpan="4">
                        <Box textAlign="center" height="200px" pt="24px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              <Flex justifyContent="flex-end">
                {!isEmpty(templates) && (
                  <Pagination
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      refetch({
                        params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                      }).then(res => {
                        setTemplates(mappingTemplate(res?.data?.data));
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      });
                    }}
                  />
                )}
              </Flex>
            </Stack>
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && (
        <AddEmailTemplateModal
          isOpen={isOpenAddModal}
          templateDetail={templateDetail}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      <PreviewTemplateModal isOpen={isOpenPreviewModal} templateDetail={templateDetail} onClose={handelCloseModal} />
      <DeleteTemplateModal
        isOpen={isOpenDeleteModal}
        templateDetail={templateDetail}
        onClose={handelCloseModal}
        refetchData={handleRefetchData}
      />
    </>
  );
}

export default EmailTemplate;
