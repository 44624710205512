import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';
import { defaultPassword } from 'utils/constant';
import { RoleOption } from 'utils/constant';
import { ModalType } from 'utils/constant';

const defaultValues = {
  username: '',
  role: {
    label: 'Admin',
    value: 'admin',
  },
};

const CreateUserModal = ({ isOpen, userDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);

  const [{ loading: createLoading }, postManualExecute] = useAxios(
    {
      url: API_ROUTES.RegisterUser,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { manual: true }
  );

  const [{ loading: updateLoading }, updateManualExecute] = useAxios(
    {
      url: API_ROUTES.UpdateUser,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    username: yup.string().required('Vui lòng nhập username'),
    role: yup.object().nullable().required('Vui lòng chọn role'),
  });
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  useEffect(() => {
    if (!isEmpty(userDetail)) {
      reset({ ...userDetail, role: RoleOption.find(item => item.value === userDetail.role) });
    }
  }, [userDetail]);

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(userDetail) ? 'Cập nhập' : 'Thêm'} người dùng thành công`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${!isEmpty(userDetail) ? 'Cập nhập' : 'Thêm'} người dùng không thành công`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    const formData = new FormData();

    for (let key in values) {
      formData.append(key, isObject(values[key]) ? values[key]?.value : values[key]);
    }

    if (!isEmpty(userDetail)) {
      updateManualExecute({
        data: formData,
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });

      return;
    }

    postManualExecute({
      data: formData,
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Tạo người dùng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="username" label="Username" isRequired />

              <FormControl pt="4">
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={defaultPassword}
                    // onChange={(event) => setPassword(event.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <SelectController styleContainer={{ pt: '4' }} control={control} name="role" label="Role" isRequired options={RoleOption} />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              {isEmpty(userDetail) ? 'Tạo' : 'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateUserModal;
