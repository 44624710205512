import React from 'react';
import { Avatar, Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Gender } from 'utils/constant';
import { ModalType } from 'utils/constant';

function CustomerRow({ user, isLast, handelUpdateUser }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={user?.logo} w="50px" borderRadius="12px" me="18px" alt="logo" />
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {user?.name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {user?.email}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {user?.code}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Add);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            p={2}
            textColor="red"
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Delete);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default CustomerRow;
