import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormControl,
  FormLabel,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { schemaTest } from 'utils/validation';
import { MAX_PHONE_NUMBER_DIGIT } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import { GenderOption } from 'utils/constant';
import { ModalType } from 'utils/constant';
import ChannelSelect from 'components/ChannelSelect/ChannelSelect';

const defaultValues = {
  name: '',
  email: '',
  code: '',
};

const CustomerRegisterDialog = ({ isOpen, customerDetail, channelName, channelId, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [error, setError] = useState(null);
  const [channelSelected, setChannelSelected] = useState(null);

  const [{ loading: createLoading }, postManualExecute] = useAxios(
    {
      url: API_ROUTES.CreateCustomer,
      method: 'post',
    },
    { manual: true }
  );

  const [{ loading: updateLoading }, updateManualExecute] = useAxios(
    {
      url: API_ROUTES.UpdateCustomer,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Vui lòng nhập email')
      .test('isValidEmail', 'Email không hợp lệ', value => {
        return schemaTest.isValidEmail(value);
      }),
    name: yup.string().required('Vui lòng nhập tên'),
  });
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(
    () => () => {
      reset(), setChannelSelected(null);
    },
    []
  );

  useEffect(() => {
    if (!isEmpty(customerDetail)) {
      reset({ ...customerDetail, gender: GenderOption.find(item => item.value === customerDetail.gender) });
    }
    setChannelSelected({ label: channelName, id: channelId });
  }, [customerDetail, channelName]);

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(customerDetail) ? 'Cập nhập' : 'Thêm'} khách hàng thành công`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${!isEmpty(customerDetail) ? 'Cập nhập' : 'Thêm'} khách hàng không thành công`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    if (isEmpty(channelSelected)) {
      setError('Vui lòng chọn nhóm');

      return;
    }

    const formData = new FormData();

    for (let key in values) {
      formData.append(key, isObject(values[key]) ? values[key]?.value : values[key]);
    }

    formData.append('channelName', channelSelected?.label);

    if (!isEmpty(customerDetail)) {
      updateManualExecute({
        data: formData,
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });

      return;
    }

    postManualExecute({
      data: formData,
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const onChangeChannel = channel => {
    setChannelSelected(channel);
    setError(null);
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Đăng ký khách hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="email" label="Email" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="name" label="Tên" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="code" label="Code" />
              <FormControl pt="4" isRequired isInvalid={error && error?.message}>
                <FormLabel minW="150px">Nhóm</FormLabel>
                <Box>
                  <ChannelSelect
                    value={channelSelected}
                    onChange={value => {
                      onChangeChannel(value);
                    }}
                  />
                  <FormErrorMessage>{error && error?.message}</FormErrorMessage>
                </Box>
              </FormControl>
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              {isEmpty(customerDetail) ? 'Tạo' : 'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CustomerRegisterDialog;
