import React, { useState, useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import { ModalType, API_ROUTES } from 'utils/constant';
import InputController from 'components/Form/InputController';

const defaultValues = {
  recipientEmail: '',
  subject: '',
  body: '',
};

const SendEmailTestModal = ({ isOpen, settingDetail, onClose }) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [emailResult, setEmailResult] = useState(null);

  const [{ loading: createLoading }, sendEmailApi] = useAxios(
    {
      url: API_ROUTES.SendEmailCampaign,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    recipientEmail: yup.string().required('Vui lòng nhập email'),
    subject: yup.string().nullable().required('Vui lòng nhập title'),
    body: yup.string().nullable().required('Vui lòng nhập body'),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(
    () => () => {
      reset();
      setEmailResult(null);
    },
    []
  );

  const onSubmit = values => {
    toast.closeAll();

    sendEmailApi({
      data: { ...values, smtpId: settingDetail?._id },
    })
      .then(res => {
        setEmailResult(res?.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          reset();
          onClose?.(ModalType.SendEmail);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Email Test</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="recipientEmail" label="Email" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="bcc" label="BCC" />
              <InputController styleContainer={{ pt: '4' }} control={control} name="subject" label="Tiêu đề" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="body" label="Nội dung" isRequired />
            </form>
            {emailResult && <Box pt={4}>{JSON.stringify(emailResult)}</Box>}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                reset();
                onClose?.(ModalType.SendEmail);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createLoading} onClick={handleSubmit(onSubmit)}>
              Gửi
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SendEmailTestModal;
