import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Button, Flex, Grid } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import { checkLogin } from '../../../utils/authentication';
import { useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import { BrandMailKey } from 'utils/constant';
import { getInitFilerChart } from 'utils/helpers';
import DailyChart from 'components/DailyChart/DailyChart';
import moment from 'moment';

const initFiler = getInitFilerChart();

export default function SystemDashboard() {
  const isLoggedIn = checkLogin();
  const history = useHistory();

  const [{ data: statistical888B }, getStatistical888B] = useAxios(
    {
      url: API_ROUTES.WaeboxEmailDaily,
    },
    { manual: true }
  );
  const [{ data: statistical8Day }, getStatistical8Day] = useAxios(
    {
      url: API_ROUTES.WaeboxEmailDaily,
    },
    { manual: true }
  );

  useEffect(() => {
    const start = moment(initFiler.startDate).startOf('day').toISOString();
    const end = moment(initFiler.endDate).endOf('day').toISOString();
    getStatistical888B({
      params: {
        startDate: start,
        endDate: end,
        brand: BrandMailKey.Ebbb,
      },
    });
    getStatistical8Day({
      params: {
        startDate: start,
        endDate: end,
        brand: BrandMailKey.Eday,
      },
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/signin');
    }
  }, [isLoggedIn, history]);

  const handleGetNewData888D = (startDate, endDate) => {
    const start = moment(startDate).startOf('day').toISOString();
    const end = moment(endDate).endOf('day').toISOString();
    getStatistical888B({
      params: {
        startDate: start,
        endDate: end,
        brand: BrandMailKey.Ebbb,
      },
    });
  };

  const handleGetNewData8Day = (startDate, endDate) => {
    const start = moment(startDate).startOf('day').toISOString();
    const end = moment(endDate).endOf('day').toISOString();
    getStatistical8Day({
      params: {
        startDate: start,
        endDate: end,
        brand: BrandMailKey.Eday,
      },
    });
  };

  const navigateEmailOpened = () => {
    history.push('/admin/system-email-opened');
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        {/* <Flex justifyContent="end" pb="20px">
          <Button variant="solid" maxH="40px" onClick={navigateEmailOpened}>
            Xem thống kê email
          </Button>
        </Flex> */}
        <Grid templateColumns={{ sm: '1fr' }} templateRows={{ lg: 'repeat(2, auto)' }} gap="20px">
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <DailyChart
              title={`Thống kê Email OTP ${BrandMailKey.Eday}`}
              labelChart="Email đã gửi"
              dataChart={statistical8Day?.data || []}
              getNewData={handleGetNewData8Day}
            />
          </Card>
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <DailyChart
              title={`Thống kê Email OTP ${BrandMailKey.Ebbb}`}
              labelChart="Email đã gửi"
              dataChart={statistical888B?.data || []}
              getNewData={handleGetNewData888D}
            />
          </Card>
        </Grid>
      </Flex>
    </>
  );
}
