import React from 'react';
import { Flex, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';

function Row({ emailDetail, isLast }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {emailDetail?.emailContent?.to}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(emailDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {emailDetail?.isOpen ? 'Đã đọc' : 'Chưa đọc'}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
