import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';
import { mappingChannelFormData } from 'utils/mapping';
import { mappingOptionSelect } from 'utils/mapping';
import { ModalType } from 'utils/constant';

const defaultValues = {
  name: '',
  customers: undefined,
};

const CreateChanelModal = ({ isOpen, channelDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [customerOption, setCustomerOption] = useState([]);
  const [{ data: customersData }] = useAxios(
    {
      url: API_ROUTES.CreateCustomer,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: createLoading }, createChannelApi] = useAxios(
    {
      url: API_ROUTES.Channel,
      method: 'post',
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateChannelApi] = useAxios(
    {
      url: API_ROUTES.UpdateChannel,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  useEffect(() => {
    setCustomerOption(mappingOptionSelect(customersData?.data));
  }, [customersData?.data]);

  useEffect(() => {
    if (!isEmpty(channelDetail)) {
      reset({
        ...channelDetail,
      });
    }
  }, [channelDetail]);

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(channelDetail) ? 'Cập nhập' : 'Tạo'} nhóm thành công`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${!isEmpty(channelDetail) ? 'Cập nhập' : 'Tạo'} nhóm không thành công`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    if (!isEmpty(channelDetail)) {
      updateChannelApi({
        data: mappingChannelFormData(values),
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });
      return;
    }

    createChannelApi({
      data: mappingChannelFormData(values),
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Tạo nhóm</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="name" label="Tên" isRequired />
              <SelectController
                isMulti
                styleContainer={{ pt: '4' }}
                control={control}
                name="customers"
                label="Khách hàng"
                options={customerOption}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              {isEmpty(channelDetail) ? 'Tạo' : 'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateChanelModal;
