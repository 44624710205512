export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
  SUPERVISOR: 'supervisor',
};

export const CountryCodeDefault = {
  GB: 'GB',
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export const STATUS_CODE = {
  Success: 0,
};

export const STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECT: 'reject',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[A-Za-z\d]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const MAX_PHONE_NUMBER_DIGIT = 10;

export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const WAEBOX_API = process.env.REACT_APP_WAEBOX_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

export const API_ROUTES = {
  Users: `${ROOT_API}/api/v1/user/users`,
  LoginPath: `${ROOT_API}/api/v1/user/login`,
  RegisterUser: `${ROOT_API}/api/v1/user/create`,
  UpdateUser: `${ROOT_API}/api/v1/user/update`,
  ChangePassUser: `${ROOT_API}/api/v1/user/change-pw`,
  ResetPassUser: `${ROOT_API}/api/v1/user/reset-password`,
  ExportTemplateUser: `${ROOT_API}/api/v1/user/export-template`,
  ImportUser: `${ROOT_API}/api/v1/user/import-manager`,
  ExportUser: `${ROOT_API}/api/v1/user/export`,
  DeleteUser: `${ROOT_API}/api/v1/user/delete`,
  CreateCustomer: `${ROOT_API}/api/v1/customers`,
  UpdateCustomer: `${ROOT_API}/api/v1/customers/change-information`,
  ExportTemplateCustomer: `${ROOT_API}/api/v1/customers/export-template`,
  ImportCustomer: `${ROOT_API}/api/v1/customers/import`,
  ExportCustomer: `${ROOT_API}/api/v1/customers/export`,
  DeleteCustomer: `${ROOT_API}/api/v1/customers/delete`,
  CustomerByChannel: channelId => `${ROOT_API}/api/v1/channel/${channelId}/customers`,
  SendMail: `${ROOT_API}/api/v1/send-mail`,
  Channel: `${ROOT_API}/api/v1/channels`,
  ChannelActive: `${ROOT_API}/api/v1/channels/active`,
  UpdateChannel: `${ROOT_API}/api/v1/channels/update`,
  DeleteChannel: `${ROOT_API}/api/v1/channels/delete`,
  ChangeStatusChannel: `${ROOT_API}/api/v1/channels/change-status`,
  Campaign: `${ROOT_API}/api/v1/campaigns`,
  EmailOfCampaign: `${ROOT_API}/api/v1/campaign-mails`,
  UpdateCampaign: `${ROOT_API}/api/v1/campaigns/update`,
  DeleteCampaign: `${ROOT_API}/api/v1/campaigns/delete`,
  OverviewCampaign: `${ROOT_API}/api/v1/campaigns/overview`,
  TrackingSendEmails: `${WAEBOX_API}/api/v1/trackings`,
  CampaignDetail: campaignId => `${ROOT_API}/api/v1/campaigns/${campaignId}`,
  SMTPServer: `${ROOT_API}/api/v1/smtp-servers`,
  UpdateSMTPServer: `${ROOT_API}/api/v1/smtp-servers/update`,
  DeleteSMTPServer: `${ROOT_API}/api/v1/smtp-servers/delete`,
  ChangeStatusSMTPServer: `${ROOT_API}/api/v1/smtp-servers/change-status`,
  SMTPServerBackupEmail: `${ROOT_API}/api/v1/smtp-servers/backup-email`,
  SMTPServerUpdateBackupEmail: `${ROOT_API}/api/v1/smtp-servers/update-backup-email`,
  SMTPServerDeleteBackupEmail: `${ROOT_API}/api/v1/smtp-servers/delete-backup-email`,
  ExportTemplateSMTPServer: `${ROOT_API}/api/v1/smtp-servers/export-template`,
  ImportSMTPServer: `${ROOT_API}/api/v1/smtp-servers/import`,
  SendMailChannel: `${ROOT_API}/api/v1/send-mail-channel`,
  SendMailCampaign: `${ROOT_API}/api/v1/send-mail-campaign`,
  SendMailCustomer: `${ROOT_API}/api/v1/send-mail-customers`,
  EmailDaily: `${ROOT_API}/api/v1/email/email-daily`,
  EmailOpenedDaily: `${ROOT_API}/api/v1/email/email-opened-daily`,
  EmailSent: `${ROOT_API}/api/v1/email/sent-emails`,
  WaeboxEmailDaily: `${WAEBOX_API}/api/v1/email/email-daily`,
  WaeboxEmailOpenedDaily: `${WAEBOX_API}/api/v1/email/email-opened-daily`,
  WaeboxEmailSent: `${WAEBOX_API}/api/v1/email/sent-emails`,
  EmailTemplate: `${ROOT_API}/api/v1/templates`,
  UpdateEmailTemplate: `${ROOT_API}/api/v1/templates/update`,
  DeleteEmailTemplate: `${ROOT_API}/api/v1/templates/delete`,
  UploadImage: `${ROOT_API}/api/v1/email/upload-image`,
  EmailCampaignFail: `${ROOT_API}/api/v1/campaign-mails`,
  ResendEmailCampaign: `${ROOT_API}/api/v1/re-send-mail-campaign`,
  SendEmailCampaign: `${ROOT_API}/api/v1/mailers-mkt/send-by-one-smtp`,
  ChangeLimitSMTPServer: `${ROOT_API}/api/v1/smtp-servers/change-limit`,
};

export const Gender = {
  male: 'Nam',
  female: 'Nữ',
};

export const GenderOption = [
  {
    label: Gender.male,
    value: 'male',
  },
  {
    label: Gender.female,
    value: 'female',
  },
];

export const PortSMTP = {
  FSF: '465',
  FES: '587',
};

export const BrandMailKey = {
  Vipphim: 'VIPPHIM',
  Eday: '8DAY',
  Ebbb: '888B',
  Tqnews: '2QNEWS',
  Tqsport: '2QSPORT',
  Tqlive: '2QLIVE',
};

export const BrandMailOption = [
  {
    label: BrandMailKey.Vipphim,
    value: BrandMailKey.Vipphim,
  },
  {
    label: BrandMailKey.Eday,
    value: BrandMailKey.Eday,
  },
  {
    label: BrandMailKey.Ebbb,
    value: BrandMailKey.Ebbb,
  },
  {
    label: BrandMailKey.Tqnews,
    value: BrandMailKey.Tqnews,
  },
  {
    label: BrandMailKey.Tqsport,
    value: BrandMailKey.Tqsport,
  },
  {
    label: BrandMailKey.Tqlive,
    value: BrandMailKey.Tqlive,
  },
];

export const ModalType = {
  Add: 'add',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  SendEmail: 'sendEmail',
  ChangeLimit: 'changeLimit',
};

export const MailStatusKey = {
  All: 'all',
  Read: 'read',
  UnRead: 'unRead',
};

export const MailStatusOption = [
  {
    label: 'Tất cả',
    value: MailStatusKey.All,
  },
  {
    label: 'Đã đọc',
    value: MailStatusKey.Read,
  },
  {
    label: 'Chưa đọc',
    value: MailStatusKey.UnRead,
  },
];

export const FileExcelValid = ['xls', 'xlsx'];

export const RoleOption = [
  {
    label: 'Admin',
    value: Roles.ADMIN,
  },
  {
    label: 'Manager',
    value: Roles.MANAGER,
  },
  {
    label: 'Supervisor',
    value: Roles.SUPERVISOR,
  },
];

export const CreateDomain = {
  CampaignInfo: 'campaignInfo',
  MailInfo: 'mailInfo',
};

export const CreateCampaignStep = {
  FirstStep: CreateDomain.CampaignInfo,
  SecondStep: CreateDomain.MailInfo,
};

export const ClassificationKey = {
  Customers: 'customer',
  Group: 'group',
};

export const ClassificationOptions = [
  {
    label: 'Khách hàng',
    value: ClassificationKey.Customers,
  },
  {
    label: 'Nhóm khách hàng',
    value: ClassificationKey.Group,
  },
];

export const DomainEmail = {
  GiaiTri8Day: '@giaitri8day.top',
  DayAsia: '@8day.asia',
  DayWiki: '@8day.wiki',
};

export const DomainEmailOption = [
  {
    label: DomainEmail.GiaiTri8Day,
    value: DomainEmail.GiaiTri8Day,
  },
  {
    label: DomainEmail.DayAsia,
    value: DomainEmail.DayAsia,
  },
  {
    label: DomainEmail.DayWiki,
    value: DomainEmail.DayWiki,
  },
];

export const ErrorMessage = {
  THE_PASSWORD_IS_INCLUDE_UPPERCASE_NORMAL_NUMBER_SPECIAL_CHARACTER_MIN_8:
    'Mật khẩu bao gồm chữ hoa, chữ thường, chữ số, ký tự đặc biệt và tối thiểu 8 kí tự',
  THE_CONFIRM_PASSWORD_DOES_NOT_MATCH: 'Xác nhận mật khẩu không giống nhau',
};
export const PortSMTPOption = [
  {
    label: PortSMTP.FES,
    value: PortSMTP.FES,
  },
  {
    label: PortSMTP.FSF,
    value: PortSMTP.FSF,
  },
];

export const StatusOption = [
  {
    label: 'Đã gửi',
    value: 'true',
  },
  {
    label: 'Chưa gửi',
    value: 'false',
  },
];
