import React, { useMemo } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, IconButton, Link, Td, Text, Tooltip, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { MdOutlineNotStarted, MdOutlinePause, MdContentCopy } from 'react-icons/md';
import pick from 'lodash/pick';
import { GiConfirmed } from 'react-icons/gi';
import { ModalType } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import { API_ROUTES } from 'utils/constant';

const CampaignStatus = {
  START: 'START',
  PENDING: 'PENDING',
  END: 'END',
};

function Row({ campaignDetail, isLast, handelUpdateCampaign, handleCopyCampaign }) {
  const history = useHistory();
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hasResendEmail = useMemo(() => campaignDetail?.isFinish && campaignDetail?.sentMailFail > 0, [campaignDetail]);
  const [, resendEmailApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ResendEmailCampaign,
    },
    { manual: true }
  );
  const [, changeCampaignStatus] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ChangeCampaignStatus,
    },
    { manual: true }
  );

  const handleResendEmail = campaignDetail => {
    resendEmailApi({ data: { campaignId: campaignDetail?._id } })
      .then(res => {
        toast({
          title: 'Gửi lại email chiến dịch thành công',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Gửi lại email chiến dịch không thành công',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleChangeCampaignStatus = campaignDetail => {
    resendEmailApi({ data: { campaignId: campaignDetail?._id } })
      .then(res => {
        toast({
          title: 'Gửi lại email chiến dịch thành công',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Gửi lại email chiến dịch không thành công',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const renderStatus = () => {
    if (campaignDetail?.isFinish) {
      return (
        <IconButton p={2} bg="transparent" _hover={{ bg: 'transparent' }}>
          <GiConfirmed size={16} color="#48bb78" />
        </IconButton>
      );
    }

    if (campaignDetail?.status === CampaignStatus.START) {
      return (
        <IconButton
          p={2}
          bg="transparent"
          onClick={() => {
            handelUpdateCampaign?.(campaignDetail, ModalType.ChangeStatus);
          }}
        >
          <MdOutlinePause color="#f56565" size={16} />
        </IconButton>
      );
    }

    return (
      <IconButton
        p={2}
        bg="transparent"
        onClick={() => {
          handelUpdateCampaign?.(campaignDetail, ModalType.ChangeStatus);
        }}
      >
        <MdOutlineNotStarted color="#4299e1" size={16} />
      </IconButton>
    );
  };

  const getStatusLabel = useMemo(() => {
    if (campaignDetail?.isFinish) {
      return 'Hoàn thành';
    }

    if (campaignDetail?.status === CampaignStatus.START) {
      return 'Dừng chiến dịch';
    }

    return 'Chạy chiến dịch';
  }, [campaignDetail]);

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {campaignDetail?.smtp?.authUserEmail}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <Text fontSize="md" color={titleColor} fontWeight="bold" noOfLines={2}>
            {campaignDetail?.recipientEmail}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" alignItems="center">
          <Text fontSize="md" color="green.400" fontWeight="bold" noOfLines={2}>
            {campaignDetail?.isSent ? 'Đã gửi' : 'Chưa gửi'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {formatDate(campaignDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <Button
            p={2}
            fontSize="sm"
            bg="transparent"
            color="blue.400"
            onClick={() => {
              handelUpdateCampaign?.(campaignDetail, ModalType.Detail);
            }}
          >
            Kết quả
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
