import React from 'react';
import { Avatar, Checkbox, Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { DeleteIcon, EditIcon, UnlockIcon } from '@chakra-ui/icons';
import { ModalType } from 'utils/constant';

function Row({ user, isLast, userSelected, handelUpdateUser, handleSelectUser }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex flexDirection={'row'} alignItems={'center'} columnGap={2}>
          <Checkbox
            id={user.id}
            value={userSelected?.includes(user.id)}
            isChecked={userSelected?.includes(user.id)}
            onChange={() => handleSelectUser(user.id)}
          />
        </Flex>
      </Td>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {user?.username}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {user?.email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" textTransform="capitalize">
            {user?.role}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Add);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.ResetPassword);
            }}
          >
            <UnlockIcon />
          </IconButton>
          <IconButton
            p={2}
            textColor="red"
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Delete);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
