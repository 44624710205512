import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import { schemaTest } from 'utils/validation';
import { BrandMailOption, BrandMailKey, PortSMTP, API_ROUTES, ModalType } from 'utils/constant';
import { PortSMTPOption } from 'utils/constant';

const defaultValues = {
  brand: '',
  port: PortSMTP.FES,
  authUserEmail: '',
  authUserPassword: '',
  host: '',
};

const AddSMTPServerModal = ({ isOpen, settingDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading: createLoading }, createSMTPServerApi] = useAxios(
    {
      url: API_ROUTES.SMTPServer,
      method: 'post',
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateSMTPServerApi] = useAxios(
    {
      url: API_ROUTES.UpdateSMTPServer,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    brand: yup.string().required('Vui lòng nhập brand'),
    authUserEmail: yup
      .string()
      .required('Vui lòng nhập email'),
      // .test('isValidEmail', 'Email không hợp lệ', value => {
      //   return schemaTest.isValidEmail(value);
      // }),
    authUserPassword: yup
      .string()
      .nullable()
      .required('Vui lòng nhập mật khẩu')
      // .test('isValidPassword', 'Mật khẩu không hợp lệ', value => {
      //   return schemaTest.isValidPassword(value);
      // }),
  });
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  useEffect(() => {
    if (!isEmpty(settingDetail)) {
      reset({
        id: settingDetail?._id,
        brand: BrandMailOption.find(item => item.value === settingDetail?.brand),
        port: settingDetail?.port,
        authUserEmail: settingDetail?.authUserEmail,
        authUserPassword: settingDetail?.authUserPassword,
      });
    }
  }, [settingDetail]);

  const handleSuccess = () => {
    toast({
      title: 'Tạo SMTP Server thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tạo SMTP Server không thành công',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    if (!isEmpty(settingDetail)) {
      updateSMTPServerApi({
        data: { ...values, port: values?.port.value },
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });

      return;
    }

    createSMTPServerApi({
      data: { ...values, port: values?.port.value },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>SMTP Server</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="brand" label="Brand Name" isRequired />
              <InputController control={control} name="host" label="Host" />
              <SelectController
                styleContainer={{ pt: '4' }}
                control={control}
                defaultValues={PortSMTP.FES}
                options={PortSMTPOption}
                isRequired
                name="port"
                label="Port"
              />
              <InputController styleContainer={{ pt: '4' }} control={control} name="authUserEmail" label="User Email" isRequired />
              <InputController styleContainer={{ pt: '4' }} control={control} name="authUserPassword" label="User Password" isRequired />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              {isEmpty(settingDetail) ? 'Lưu' : 'Cập nhập'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddSMTPServerModal;
