import React, { useMemo } from 'react';
import { ViewIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { useHistory, NavLink } from 'react-router-dom';
import { ModalType } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';

function Row({ campaignDetail, isLast, handelUpdateCampaign }) {
  const history = useHistory();
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hasResendEmail = useMemo(() => campaignDetail?.isFinish && campaignDetail?.sentMailFail > 0, [campaignDetail]);
  const [, resendEmailApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ResendEmailCampaign,
    },
    { manual: true }
  );

  const handleResendEmail = campaignDetail => {
    resendEmailApi({ data: { campaignId: campaignDetail?._id } })
      .then(res => {
        toast({
          title: 'Gửi lại email chiến dịch thành công',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Gửi lại email chiến dịch không thành công',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <NavLink to={`/admin/campaign/email?campaignId=${campaignDetail?._id}&campaignName=${campaignDetail?.name}`}>
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {campaignDetail?.name}
            </Text>
            <Text fontSize="sm" color={titleColor} minWidth="100%">
              {campaignDetail?._id}
            </Text>
          </NavLink>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" alignItems="center">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {campaignDetail?.recipients || 0}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" alignItems="center">
          <Text fontSize="md" color="green.400" fontWeight="bold" noOfLines={2}>
            {campaignDetail?.sentMailSuccess || 0}
          </Text>
          <Text fontSize="sm" color={textColor} noOfLines={2}>
            {`${campaignDetail?.ratioSentMailSuccess || 0}%`}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" alignItems="center">
          <Text fontSize="md" color="blue.400" fontWeight="bold" noOfLines={2}>
            {campaignDetail?.openedMail || 0}
          </Text>
          <Text fontSize="sm" color={textColor} noOfLines={2}>
            {`${campaignDetail?.ratioOpenedMail || 0}%`}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column" alignItems="center">
          <Text fontSize="md" color="red.400" fontWeight="bold" noOfLines={2}>
            {campaignDetail?.sentMailFail || 0}
          </Text>
          <Text fontSize="sm" color={textColor} noOfLines={2}>
            {`${campaignDetail?.ratioSentMailFail || 0}%`}
          </Text>
          {campaignDetail?.sentMailFail > 0 && (
            <Text
              fontSize="xs"
              cursor="pointer"
              textColor="blue.400"
              textDecorationLine="underline"
              _hover={{ textDecorationLine: 'none', opacity: '.8' }}
              onClick={() => {
                history.push({
                  pathname: '/admin/email-fail',
                  search: `?campaignId=${campaignDetail._id}`,
                });
              }}
            >
              Chi tiết
            </Text>
          )}
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={hasResendEmail ? 'blue.400' : textColor}
            fontWeight="bold"
            noOfLines={2}
            cursor={hasResendEmail ? 'pointer' : 'default'}
            onClick={() => {
              if (hasResendEmail) {
                handleResendEmail(campaignDetail);
              }
            }}
          >
            {hasResendEmail ? 'Gửi lại' : campaignDetail?.isFinish ? 'Hoàn thành' : 'Đang gửi'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {formatDate(campaignDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateCampaign?.(campaignDetail, ModalType.Detail);
            }}
          >
            <ViewIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
