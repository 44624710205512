import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Td,
} from '@chakra-ui/react';
import { TablePagination } from '@trendmicro/react-paginations';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Row from './components/Row';
import CreateGroupModal from './components/CreateChannelModal';
import { API_ROUTES } from 'utils/constant';
import { initialFilter } from 'utils/constant';
import { mappingChannel } from 'utils/mapping';
import Pagination from 'components/Pagination/Pagination';
import DeleteChanelModal from './components/DeteleChanndelModal';
import { ModalType } from 'utils/constant';
import ImportCustomerDialog from 'components/Customer/ImportCustomerDialog';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import InputSearch from 'components/InputSearch/InputSearch';
import { isEmpty } from 'lodash';

const Channel = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const [channels, setChannels] = useState([]);
  const [channelDetail, setChannelDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenImportModal, onOpen: onOpenImportModal, onClose: onCloseImportModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.Import]: onOpenImportModal,
    }),
    [onOpenAddModal, onOpenDeleteModal, onOpenImportModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.Import]: onCloseImportModal,
    }),
    [onCloseAddModal, onCloseDeleteModal, onCloseImportModal]
  );

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: API_ROUTES.Channel,
      params: filter,
    },
    {
      manual: true,
      useCache: false,
    }
  );

  useEffect(() => {
    refetch()
      .then(res => {
        setChannels(mappingChannel(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handelUpdateChannel = (customerDetail, modalType) => {
    setChannelDetail(customerDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    setChannelDetail(null);
    closeModal?.[modalType]?.();
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        setChannels(mappingChannel(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Nhóm khách hàng
            </Text>
            <Flex pt="22px" justifyContent="space-between">
              <Flex flex="1">
                <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
              </Flex>
              <Flex justifyContent="flex-end">
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                  Tạo nhóm
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenImportModal}>
                  Import
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    Tên
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Tổng số khách hàng
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Trạng trái
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {channels?.map((row, index, arr) => (
                  <Row
                    key={row.id}
                    channelDetail={row}
                    isLast={index === arr.length - 1 ? true : false}
                    handelUpdateChannel={handelUpdateChannel}
                    refetch={refetch}
                  />
                ))}
                {isEmpty(channels) && !loading && (
                  <Tr>
                    <Td colSpan="4">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {!isEmpty(channels) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setChannels(mappingChannel(res?.data?.data));
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && (
        <CreateGroupModal
          channelDetail={channelDetail}
          isOpen={isOpenAddModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteChanelModal
          channelDetail={channelDetail}
          isOpen={isOpenDeleteModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenImportModal && <ImportCustomerDialog isOpen={isOpenImportModal} onClose={handelCloseModal} refetchData={handleRefetchData} />}
    </>
  );
};

export default Channel;
