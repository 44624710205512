import React from 'react';
import { HomeIcon, StatsIcon, PersonIcon } from 'components/Icons/Icons';
import { Roles } from 'utils/constant';
import Dashboard from 'views/Dashboard/Dashboard.js';
import Customer from 'views/Dashboard/Customer/Customer';
import Channel from 'views/Dashboard/Channel/Channel';
import SignIn from 'views/Pages/SignIn';
import SMTPServer from 'views/Dashboard/SMTPServer/SMTPServer';
import Profile from 'views/Dashboard/Profile';
import User from 'views/Dashboard/User/User';
import Email from 'views/Dashboard/Email/Email';
import SystemDashboard from 'views/Dashboard/SystemDashboard/SysTemDashboard';
import SystemEmailOpened from 'views/Dashboard/SystemEmailOpened/SystemEmailOpened';
import SystemEmail from 'views/Dashboard/SystemEmail/SystemEmail';
import EmailTemplate from 'views/Dashboard/EmailTemplate/EmailTemplate';
import Campaign from 'views/Dashboard/Campaign/Campaign';
import EmailFail from 'views/Dashboard/EmailFail';
import History8B from 'views/Dashboard/History8B/History8B';
import History8Day from 'views/Dashboard/History8Day/History8Day';
import CampaignEmail from 'views/Dashboard/Campaign/components/Email';
var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/system-dashboard',
    name: 'Overview',
    icon: <StatsIcon color="inherit" />,
    component: SystemDashboard,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.SUPERVISOR],
  },
  {
    path: '/history8day',
    name: 'History 8Day',
    icon: <StatsIcon color="inherit" />,
    component: History8Day,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.SUPERVISOR],
  },
  {
    path: '/history888b',
    name: 'History 888B',
    icon: <StatsIcon color="inherit" />,
    component: History8B,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.SUPERVISOR],
  },
  {
    path: '/system-email-opened',
    name: 'System Email Opened',
    icon: <StatsIcon color="inherit" />,
    component: SystemEmailOpened,
    layout: '/admin',
    role: [Roles.ADMIN],
    redirect: true,
  },
  {
    path: '/system-email',
    name: 'System Email',
    icon: <HomeIcon color="inherit" />,
    component: SystemEmail,
    layout: '/admin',
    role: [Roles.ADMIN],
    redirect: true,
  },
  {
    path: '/email',
    name: 'Email',
    icon: <StatsIcon color="inherit" />,
    component: Email,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
    redirect: true,
  },
  {
    path: '/customer',
    name: 'Quản lý khách hàng',
    icon: <StatsIcon color="inherit" />,
    component: Customer,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
    redirect: true,
  },
  {
    path: '/user',
    name: 'Quản lý người dùng',
    icon: <StatsIcon color="inherit" />,
    component: User,
    layout: '/admin',
    role: [Roles.ADMIN],
  },
  {
    path: '/group',
    name: 'Quản lý khách hàng',
    icon: <StatsIcon color="inherit" />,
    component: Channel,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/smtp-server',
    name: 'SMTP Server',
    icon: <StatsIcon color="inherit" />,
    component: SMTPServer,
    layout: '/admin',
    role: [Roles.ADMIN],
  },
  {
    path: '/email-template',
    name: 'Email Template',
    icon: <StatsIcon color="inherit" />,
    component: EmailTemplate,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/campaign/email',
    name: 'Email chiến dịch',
    icon: <StatsIcon color="inherit" />,
    component: CampaignEmail,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
    redirect: true,
  },
  {
    path: '/campaign',
    name: 'Email chiến dịch',
    icon: <StatsIcon color="inherit" />,
    component: Campaign,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/email-fail',
    name: 'Email gửi thất bại',
    icon: <StatsIcon color="inherit" />,
    component: EmailFail,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
    redirect: true,
  },
  // {
  //   path: '/email-customer',
  //   name: 'Email cho khách hàng',
  //   icon: <StatsIcon color="inherit" />,
  //   component: EmailForCustomer,
  //   layout: '/admin',
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  // {
  //   path: '/email-channel',
  //   name: 'Email cho chiến dịch',
  //   icon: <StatsIcon color="inherit" />,
  //   component: EmailForChannel,
  //   layout: '/admin',
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: '/admin',
        role: [Roles.ADMIN, Roles.MANAGER, Roles.USER, Roles.GUEST],
      },
      {
        path: '/signin',
        component: SignIn,
        layout: '/auth',
      },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
];
export default dashRoutes;
