import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { initialFilter, StatusOption } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './Row';
import { Select } from 'chakra-react-select';
import { mappingOptionSelect } from 'utils/mapping';
import EmailResultModal from './EmailResultModal';

const CampaignEmail = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const campaignId = query.get('campaignId');
  const campaignName = query.get('campaignName');

  const toast = useToast();
  const { isOpen: isOpenDetailModal, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();

  const [filter, setFilter] = useState({ ...initialFilter });
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [smtpSeverOption, setSMTPServerOption] = useState();

  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: API_ROUTES.EmailOfCampaign,
      params: filter,
    },
    {
      manual: true,
    }
  );
  const [{ data: smtpServerData }] = useAxios({
    url: API_ROUTES.SMTPServer,
  });

  useEffect(() => {
    const newFilter = {
      ...filter,
      campaignId,
    };

    refetch({
      params: { ...newFilter },
    }).then(res => {
      setFilter(newFilter);
    });
  }, [campaignId]);

  useEffect(() => {
    setSMTPServerOption(mappingOptionSelect(smtpServerData?.data, 'authUserEmail'));
  }, [smtpServerData?.data]);

  const handelUpdateCampaign = campaignDetail => {
    setCampaignDetail(campaignDetail);
    onOpenDetailModal();
  };

  const handelCloseModal = () => {
    onCloseDetailModal();
    setCampaignDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, isSent: filter.isSent?.value, smtp: filter?.smtp?.value },
    })
      .then(res => {})
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
      isSent: null,
      smtp: null,
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Email đã gửi trong chiến dịch: {campaignName}
            </Text>
            <Flex pt="22px" justifyContent="space-between">
              <Flex flex="1" gap="12px">
                <FormControl mt="2px" display="flex" alignItems="center" maxW="300px">
                  <FormLabel m="0" pr="10px">
                    Trạng thái
                  </FormLabel>
                  <Select
                    options={StatusOption}
                    value={filter.isSent}
                    placeholder={'Chọn'}
                    chakraStyles={{
                      container: (provided, state) => ({
                        ...provided,
                        flex: 1,
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 10,
                      }),
                    }}
                    onChange={e => {
                      setFilter(prev => ({
                        ...prev,
                        isSent: e,
                      }));
                    }}
                  />
                </FormControl>
                <FormControl mt="2px" display="flex" alignItems="center" maxW="350px">
                  <FormLabel m="0" pr="10px">
                    SMTP
                  </FormLabel>
                  <Select
                    options={smtpSeverOption}
                    value={filter.smtp}
                    placeholder={'Chọn'}
                    chakraStyles={{
                      container: (provided, state) => ({
                        ...provided,
                        flex: 1,
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 10,
                      }),
                    }}
                    onChange={e => {
                      setFilter(prev => ({
                        ...prev,
                        smtp: e,
                      }));
                    }}
                  />
                </FormControl>
                <Flex justifyContent="center" alignItems="center" gap="12px">
                  <Button variant="outline" fontSize="12px" fontWeight="700" maxH="30px" onClick={onClearSearch}>
                    Cài lại
                  </Button>
                  <Button variant="primary" maxH="30px" fontSize="12px" onClick={onFilter}>
                    Lọc
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Email gửi
                    </Th>
                    <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                      Email nhận
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Trạng thái
                    </Th>
                    <Th minWidth="150px" borderColor={borderColor} color="gray.400" textAlign="center">
                      Ngày gửi
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" />
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => {
                    return (
                      <Row
                        key={row._id}
                        campaignDetail={row}
                        isLast={index === arr.length - 1 ? true : false}
                        handelUpdateCampaign={handelUpdateCampaign}
                      />
                    );
                  })}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="8">
                        <Box textAlign="center" height="200px" pt="24px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>
            {!isEmpty(data?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isOpenDetailModal && (
        <EmailResultModal
          isOpen={isOpenDetailModal}
          campaignDetail={campaignDetail}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
    </>
  );
};

export default CampaignEmail;
