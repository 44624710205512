import React from 'react';
import { Avatar, Checkbox, Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import useAxios from 'axios-hooks';
import { useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';

function Row({ channelDetail, isLast, handelUpdateChannel, refetch }) {
  const toast = useToast();
  const history = useHistory();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [{ loading }, changeStatusChannelApi] = useAxios(
    {
      url: API_ROUTES.ChangeStatusChannel,
      method: 'post',
    },
    { manual: true }
  );

  const handleUpdateStatusChannel = () => {
    changeStatusChannelApi({
      data: { id: channelDetail?.id },
    })
      .then(res => {
        refetch();
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Đổi trạng thái không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const goCustomer = () => {
    history.push({
      pathname: '/admin/customer',
      search: `?channelId=${channelDetail.id}&channelName=${channelDetail.name}`,
    });
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={channelDetail?.logo} w="50px" borderRadius="12px" me="18px" alt="logo" />
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {channelDetail?.name}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {channelDetail?.customers?.length || 0}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex flexDirection={'row'} alignItems={'center'} columnGap={2}>
          <Checkbox
            id={channelDetail?.id}
            value={channelDetail?.isActive}
            isChecked={channelDetail?.isActive}
            onChange={handleUpdateStatusChannel}
          />
          <Text fontSize="md" color={channelDetail?.isActive ? 'green' : 'red'} fontWeight="bold">
            ACTIVE
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateChannel(channelDetail, ModalType.Add);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton p={2} bg="transparent" onClick={goCustomer}>
            <InfoIcon />
          </IconButton>
          <IconButton
            p={2}
            textColor="red"
            bg="transparent"
            onClick={() => {
              handelUpdateChannel(channelDetail, ModalType.Delete);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
