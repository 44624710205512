import {
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
function Row(props) {
  const { result,day, from, isLast } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  return (
    <>
      <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {result?.info?.envelope.to === undefined ? from.to :result?.info?.envelope.to}
          </Text>
        </Td>
        <Td borderColor={borderColor} textAlign="center" borderBottom={isLast ? "none" : null}>
          <Text fontSize="md"  w="100px" textAlign="center" color={textColor} fontWeight="bold">
            {result?.code === 0 ? "Thành Công" : "Thất Bại"}
          </Text>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
    
      </Tr>
    </>
  );
}

export default Row;
