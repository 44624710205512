import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { Select } from 'chakra-react-select';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, initialFilter } from 'utils/constant';
import { downloadFile } from 'utils/helpers';
import CreateUserModal from './components/CreateUserModal';
import Row from './components/Row';
import { mappingUsers } from 'utils/mapping';
import { RoleOption } from 'utils/constant';
import { ModalType } from 'utils/constant';
import DeleteUserModal from './components/DeleteUserModal';
import ResetPassUserModal from './components/ResetPasswordUserModal';
import InputSearch from 'components/InputSearch/InputSearch';
import ImportUserModal from './components/ImportUserModal';

const RoleOptionFilter = [
  {
    label: 'Tất cả',
    value: 'all',
  },
  ...RoleOption,
];

const User = () => {
  const inputImportRef = useRef();
  const toast = useToast();
  const [filter, setFilter] = useState({
    ...initialFilter,
    role: 'all',
  });
  const [users, setUsers] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [error, setError] = useState();
  const [userSelected, setUserSelected] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenResetPasswordModal, onOpen: onOpenResetPasswordModal, onClose: onCloseResetPasswordModal } = useDisclosure();
  const { isOpen: isOpenImportModal, onOpen: onOpenImportModal, onClose: onCloseImportModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.ResetPassword]: onOpenResetPasswordModal,
      [ModalType.Import]: onOpenImportModal,
    }),
    [onOpenAddModal, onOpenDeleteModal, onOpenResetPasswordModal, onOpenImportModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.ResetPassword]: onCloseResetPasswordModal,
      [ModalType.Import]: onCloseImportModal,
    }),
    [onCloseAddModal, onCloseDeleteModal, onCloseResetPasswordModal, onCloseImportModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.Users,
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    },
    { manual: true }
  );
  const [{ loading: exportLoading }, exportCustomerApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportUser,
      params: filter,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );
  const [{ loading: downloadLoading }, downloadTemplateApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportTemplateUser,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    refetch({
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    })
      .then(res => {
        setUsers(mappingUsers(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handelUpdateUser = (userDetail, modalType) => {
    setUserDetail(userDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setUserDetail(null);
  };

  const onExportUser = () => {
    setError('');
    exportCustomerApi({
      params: {
        pageSize: filter.pageSize,
        pageIndex: filter.pageIndex,
        ...(!isEmpty(userSelected) && { userIds: userSelected.join(',') }),
      },
    })
      .then(response => {
        downloadFile(response?.data, 'user');
        setUserSelected([]);
        setIsSelectAll(false);
        toast({
          title: 'Export thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Export không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onDownloadTemplate = () => {
    setError('');
    downloadTemplateApi()
      .then(response => {
        downloadFile(response?.data, 'user-template');
        toast({
          title: 'Tải template thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tải template không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    })
      .then(res => {
        setUsers(mappingUsers(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const handleSelectUser = userId => {
    const userSelectedTmp = [...userSelected];
    const userIdExists = userSelectedTmp.find(item => item === userId);

    if (userIdExists) {
      setUserSelected(userSelectedTmp.filter(item => item !== userId));
      return;
    }

    userSelectedTmp.push(userId);
    setUserSelected(userSelectedTmp);
  };

  const handleSelectAll = isSelected => {
    setIsSelectAll(!isSelected);

    if (isSelected) {
      setUserSelected([]);
      return;
    }

    const allUserId = users.map(item => item?.id);

    setUserSelected(uniq([...userSelected, ...allUserId]));
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Người dùng
            </Text>
            <Flex pt="22px" flexWrap="wrap">
              <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
              <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                <FormLabel m="0" pr="10px">
                  Role
                </FormLabel>
                <Select
                  options={RoleOptionFilter}
                  value={RoleOptionFilter.find(item => item.value === filter.role)}
                  placeholder={'Chọn'}
                  chakraStyles={{
                    container: (provided, state) => ({
                      ...provided,
                      flex: 1,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      role: e?.value,
                    }));
                  }}
                />
              </FormControl>
              <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                Lọc
              </Button>
            </Flex>
            <Flex pt="10px" justifyContent="flex-end">
              <Flex>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                  Thêm người dùng
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenImportModal}>
                  Import
                </Button>
                <Button variant="primary" maxH="30px" m="10px" isLoading={exportLoading} onClick={onExportUser}>
                  Export
                </Button>
                <Button variant="primary" maxH="30px" m="10px" isLoading={downloadLoading} onClick={onDownloadTemplate}>
                  Tải template
                </Button>
              </Flex>
              {!!error && <Text color={'red.300'}>{error}</Text>}
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th borderColor={borderColor} color="gray.400">
                    <Flex flexDirection={'row'} alignItems={'center'}>
                      <Checkbox
                        id="select-all"
                        value={isSelectAll}
                        isChecked={isSelectAll}
                        onChange={() => {
                          handleSelectAll(isSelectAll);
                        }}
                      />
                    </Flex>
                  </Th>
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    Username
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Email
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Role
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {users?.map((row, index, arr) => {
                  return (
                    <Row
                      key={row.id}
                      user={row}
                      isLast={index === arr.length - 1 ? true : false}
                      userSelected={userSelected}
                      handleSelectUser={handleSelectUser}
                      handelUpdateUser={handelUpdateUser}
                    />
                  );
                })}
                {isEmpty(users) && !loading && (
                  <Tr>
                    <Td colSpan="4">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {!isEmpty(users) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { role: filter?.role !== 'all' ? filter?.role : '', pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setUsers(mappingUsers(res?.data?.data));
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                    setIsSelectAll(false);
                    setUserSelected([]);
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && (
        <CreateUserModal userDetail={userDetail} isOpen={isOpenAddModal} onClose={handelCloseModal} refetchData={handleRefetchData} />
      )}
      <DeleteUserModal userDetail={userDetail} isOpen={isOpenDeleteModal} onClose={handelCloseModal} refetchData={handleRefetchData} />
      <ResetPassUserModal
        userDetail={userDetail}
        isOpen={isOpenResetPasswordModal}
        onClose={handelCloseModal}
        refetchData={handleRefetchData}
      />
      {isOpenImportModal && <ImportUserModal isOpen={isOpenImportModal} onClose={handelCloseModal} refetchData={handleRefetchData} />}
    </>
  );
};

export default User;
