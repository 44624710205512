import React, { useState } from 'react';
import { Button, Checkbox, Flex, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon, ChevronUpIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { IoIosSend } from 'react-icons/io';
import { FaUnlock } from 'react-icons/fa';
import { ModalType } from 'utils/constant';
import ExpandedRow from './ExpandedRow';
// import AddSMTPServerDialog from './AddSMTPServerDialog';
// import ExpandedRow from './ExpandedRow';
// import AddBackupEmail from './AddBackupEmail';

function SMTPRow({ setting, isLast, handelUpdateSetting }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = id => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter(rowId => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {setting?.brand}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?.port}
          </Text>
        </Td>
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {setting?.secure ? "Secure" : "Insecure"}
            </Text>
          </Td> */}
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?.authUserEmail}
          </Text>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?._id}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?.authUserPassword}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?.totalEmailSended}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {setting?.mailSentInDay}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex alignItems="center" gap="10px">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {setting?.isLimit ? 'Yes' : 'No'}
            </Text>
            {setting?.isLimit && (
              <Button p="0px" bg="transparent" variant="no-effects" onClick={() => handelUpdateSetting(setting, ModalType.ChangeLimit)}>
                <FaUnlock size={14} />
              </Button>
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex flexDirection={'row'} alignItems={'center'} columnGap={2}>
            <Checkbox
              id={setting._id}
              value={setting.isActive}
              isChecked={setting.isActive}
              onChange={() => handelUpdateSetting(setting, ModalType.ChangeStatus)}
            />
            <Text fontSize="md" color={setting.isActive ? 'green' : 'red'} fontWeight="bold" textTransform={'capitalize'}>
              Active
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex direction={'row'} columnGap={1}>
            <Button
              ml={2}
              p="0px"
              bg="transparent"
              variant="no-effects"
              onClick={() => handelUpdateSetting(setting, ModalType.EmailBackup)}
            >
              <AddIcon />
            </Button>
            {setting.backupEmails?.length ? (
              <Box as="tr">
                <IconButton
                  icon={expandedRows?.includes(setting._id) ? <ChevronDownIcon /> : <ChevronUpIcon />}
                  onClick={() => toggleRow(setting._id)}
                  aria-label={expandedRows?.includes(setting?._id) ? 'Hide details' : 'Show details'}
                />
              </Box>
            ) : (
              ''
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex>
            <Button p="0px" bg="transparent" variant="no-effects" onClick={() => handelUpdateSetting(setting, ModalType.SendEmail)}>
              <IoIosSend size={16} color="#4299e1" />
            </Button>
            <Button p="0px" bg="transparent" variant="no-effects" onClick={() => handelUpdateSetting(setting, ModalType.Add)}>
              <EditIcon />
            </Button>
            <Button
              ml={2}
              p="0px"
              textColor="red"
              bg="transparent"
              variant="no-effects"
              onClick={() => handelUpdateSetting(setting, ModalType.Delete)}
            >
              <DeleteIcon />
            </Button>
          </Flex>
        </Td>
      </Tr>
      {expandedRows?.includes(setting?._id) && (
        <Tr>
          <Td colSpan={8}>
            <ExpandedRow emailBackup={setting?.backupEmails} smtpId={setting._id} refetch={refetch} />
          </Td>
        </Tr>
      )}
    </>
  );
}

export default SMTPRow;
