import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Row from './components/Row';
import CreateCampaign from './components/CreateCampaign';
import { initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import { mappingCampaign } from 'utils/mapping';
import DeleteCampaignModal from './components/DeleteCampaign';
import { ModalType } from 'utils/constant';
import CampaignDetailModal from './components/CampaignDetailModal';
import InputSearch from 'components/InputSearch/InputSearch';
import { isEmpty } from 'lodash';

const Campaign = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const [campaignDetail, setCampaignDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const { isOpen: isOpenDetailModal, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onRegisterOpen,
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onRegisterOpen, onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onRegisterClose,
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onRegisterClose, onCloseDetailModal]
  );

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: API_ROUTES.Campaign,
      params: filter,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateCampaign = (campaignDetail, modalType) => {
    setCampaignDetail(campaignDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setCampaignDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {})
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Chiến dịch
            </Text>
            <Flex pt="22px" justifyContent="space-between">
              <Flex flex="1">
                <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
              </Flex>
              <Button variant="primary" maxH="30px" m="10px" onClick={onRegisterOpen}>
                Tạo chiến dịch
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                      Tổng người nhận
                    </Th>
                    <Th minWidth="250px" borderColor={borderColor} color="gray.400">
                      Tổng mail gửi thành công
                    </Th>
                    <Th minWidth="180px" borderColor={borderColor} color="gray.400">
                      Tổng mail đã mở
                    </Th>
                    <Th minWidth="240px" borderColor={borderColor} color="gray.400">
                      Tổng mail gửi thất bại
                    </Th>
                    <Th minWidth="150px" borderColor={borderColor} color="gray.400">
                      Trạng thái
                    </Th>
                    <Th minWidth="150px" borderColor={borderColor} color="gray.400">
                      Ngày gửi
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" />
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => {
                    return (
                      <Row
                        key={row._id}
                        campaignDetail={row}
                        isLast={index === arr.length - 1 ? true : false}
                        handelUpdateCampaign={handelUpdateCampaign}
                      />
                    );
                  })}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="8">
                        <Box textAlign="center" height="200px" pt="24px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>
            {!isEmpty(data?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
      {isRegisterOpen && (
        <CreateCampaign isOpen={isRegisterOpen} onOpen={onRegisterOpen} onClose={handelCloseModal} refetchData={handleRefetchData} />
      )}
      {isOpenDetailModal && (
        <CampaignDetailModal
          isOpen={isOpenDetailModal}
          campaignDetail={campaignDetail}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
    </>
  );
};

export default Campaign;
