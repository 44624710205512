import React, { useEffect, useState } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import { getInitFilerChart } from 'utils/helpers';
import { formatDate } from 'utils/helpers';
import { BrandMailKey } from 'utils/constant';
import EmailOpened from 'components/EmailOpened/EmailOpened';
import { MailStatusKey } from 'utils/constant';

const initFiler = getInitFilerChart();

const Email = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const brand = query.get('brand');
  const isOpen = query.get('isOpen');
  const pageSize = query.get('pageSize');
  const pageIndex = query.get('pageIndex');

  const [{ data, loading, error }, getEmail] = useAxios(
    {
      url: API_ROUTES.EmailSent,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getEmail({
      params: {
        pageSize: pageSize || 10,
        pageIndex: pageIndex || 0,
        startDate: startDate ? startDate : formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: endDate ? endDate : formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        isOpen,
      },
    });
  }, [startDate, endDate, brand, isOpen, pageSize, pageIndex]);

  const handleFilter = params => {
    getEmail({
      params: {
        ...params,
        startDate: startDate ? startDate : formatDate(params.startDate, 'YYYY-MM-DD'),
        endDate: endDate ? endDate : formatDate(params.endDate, 'YYYY-MM-DD'),
        ...(params.status?.value !== MailStatusKey.All && { isOpen: params.status?.value === MailStatusKey.Read ? true : false }),
      },
    }).then(() => {
      const isOpen = params.status?.value === MailStatusKey.All ? '' : params.status?.value === MailStatusKey.Read ? true : false;

      history.replace({
        pathname: '/admin/email',
        search: `?startDate=${formatDate(params.startDate, 'YYYY-MM-DD')}&endDate=${formatDate(
          params.endDate,
          'YYYY-MM-DD'
        )}&isOpen=${isOpen}&pageSize=${params.pageSize}&pageIndex=${params.pageIndex}`,
      });
    });
  };

  return (
    <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
      <EmailOpened
        data={data}
        startDate={startDate}
        endDate={endDate}
        status={isOpen}
        pageSize={pageSize}
        pageIndex={pageIndex}
        handleFilter={handleFilter}
      />
    </Box>
  );
};

export default Email;
