import React, { useEffect, useState } from 'react';
import {
  Button,
  useToast,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';
import { mappingChannelFormData } from 'utils/mapping';
import { mappingOptionSelect } from 'utils/mapping';
import EditorController from 'components/Form/EditorController';
import { ModalType } from 'utils/constant';

const defaultValues = {
  name: '',
  content: '',
};

const AddEmailTemplateModal = ({ isOpen, templateDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [{ loading: createLoading }, createTemplateApi] = useAxios(
    {
      url: API_ROUTES.EmailTemplate,
      method: 'post',
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateTemplateApi] = useAxios(
    {
      url: API_ROUTES.UpdateEmailTemplate,
      method: 'post',
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
    content: yup.string().required('Vui lòng nhập nội dung'),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  useEffect(() => {
    if (!isEmpty(templateDetail)) {
      reset({
        ...templateDetail,
      });
    }
  }, [templateDetail]);

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(templateDetail) ? 'Cập nhập' : 'Tạo'} Template thành công`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${!isEmpty(templateDetail) ? 'Cập nhập' : 'Tạo'} Template không thành công`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    if (!isEmpty(templateDetail)) {
      updateTemplateApi({
        data: values,
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });
      return;
    }

    createTemplateApi({
      data: values,
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <Modal
        size="4xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="uppercase">Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <InputController control={control} name="name" label="Tên" isRequired />
              <FormControl pt="4">
                <EditorController control={control} name="content" />
              </FormControl>
            </form>
            <Flex direction="column" pt="10px">
              <Text fontSize="md" fontWeight="600">
                Thông tin động:
              </Text>
              <UnorderedList fontSize="sm">
                <ListItem>{`{{name}}: Tên khách hàng`}</ListItem>
                <ListItem>{`{{email}}: Email khách hàng`}</ListItem>
                <ListItem>{`{{code}}: Code khách hàng`}</ListItem>
              </UnorderedList>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="red" ml={3} isLoading={createLoading || updateLoading} onClick={handleSubmit(onSubmit)}>
              {isEmpty(templateDetail) ? 'Tạo' : 'Cập nhập'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEmailTemplateModal;
