import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { initialFilter, API_ROUTES } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import SMTPRow from './Components/Row';
import AddSMTPServerModal from './Components/AddSMTPSeverModal';
import { ModalType } from 'utils/constant';
import DeleteSMTPServerModal from './Components/DeleteSMTPServerModal';
import ChangeStatusModal from './Components/ChangeStatusModal';
import AddEmailBackupModal from './Components/AddEmailBackupModal';
import { downloadFile } from 'utils/helpers';
import { FileExcelValid } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
import SendEmailTestModal from './Components/SendEmailTestModal';
import ChangeLimitModal from './Components/ChangeLimitModal';

function SMTPServer() {
  const inputImportRef = useRef();
  const toast = useToast();
  const [filter, setFilter] = useState({ ...initialFilter });
  const [settingDetail, setSettingDetail] = useState(null);
  const [error, setError] = useState();
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const { isOpen: isOpenChangeStatusModal, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const { isOpen: isOpenAddEmailBackupModal, onOpen: onOpenAddEmailBackupModal, onClose: onCloseAddEmailBackupModal } = useDisclosure();
  const { isOpen: isOpenSendEmailModal, onOpen: onOpenSendEmailModal, onClose: onCloseSendEmailModal } = useDisclosure();
  const { isOpen: isOpenChangeLimitModal, onOpen: onOpenChangeLimitModal, onClose: onCloseChangeLimitModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
      [ModalType.EmailBackup]: onOpenAddEmailBackupModal,
      [ModalType.SendEmail]: onOpenSendEmailModal,
      [ModalType.ChangeLimit]: onOpenChangeLimitModal,
    }),
    [onOpenAddModal, onOpenDeleteModal, onOpenChangeStatusModal, onOpenAddEmailBackupModal, onOpenSendEmailModal, onOpenChangeLimitModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
      [ModalType.EmailBackup]: onCloseAddEmailBackupModal,
      [ModalType.SendEmail]: onCloseSendEmailModal,
      [ModalType.ChangeLimit]: onCloseChangeLimitModal,
    }),
    [
      onCloseAddModal,
      onCloseDeleteModal,
      onCloseChangeStatusModal,
      onCloseAddEmailBackupModal,
      onCloseSendEmailModal,
      onCloseChangeLimitModal,
    ]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.SMTPServer,
      params: filter,
    },
    {
      manual: true,
    }
  );
  const [{ loading: importLoading }, importSMTPServerApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ImportSMTPServer,
    },
    { manual: true }
  );
  const [{ loading: downloadLoading }, downloadTemplateApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportTemplateSMTPServer,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    handleRefetchData();
  }, []);

  const handelUpdateSetting = (settingDetail, modalType) => {
    setSettingDetail(settingDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseAddModal = modalType => {
    closeModal?.[modalType]?.();
    setSettingDetail(null);
  };

  const handleFileSelect = e => {
    if (e?.target?.files?.[0]) {
      const smtpFile = e.target.files[0];
      const extensionFile = smtpFile?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();
        formData.append('smtpFile', smtpFile);

        importSMTPServerApi({ data: formData })
          .then(() => {
            refetch();
            toast({
              title: 'Import khách hàng thành công',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          })
          .catch(error => {
            toast({
              title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Import khách hàng không thành công',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          });
      } else {
        setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, ,xls');
      }
    }
  };

  const onDownloadTemplate = () => {
    setError('');
    downloadTemplateApi()
      .then(response => {
        downloadFile(response?.data, 'smtp-server-template');
        toast({
          title: 'Tải template thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Tải template không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {})
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            SMTP Server
          </Text>
          <Flex pt="22px" justifyContent="space-between">
            <Flex flex="1">
              <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
              <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                Lọc
              </Button>
            </Flex>
            <Flex direction="column" alignItems="flex-end">
              <Flex>
                <Button variant="primary" maxH="30px" m="10px" onClick={onOpenAddModal}>
                  Thêm
                </Button>
                <Box>
                  <input type="file" hidden ref={inputImportRef} onChange={handleFileSelect} />
                  <Button
                    variant="primary"
                    maxH="30px"
                    m="10px"
                    isLoading={importLoading}
                    onClick={() => {
                      setError('');
                      inputImportRef?.current?.click();
                    }}
                  >
                    Import
                  </Button>
                </Box>
                <Button variant="primary" maxH="30px" m="10px" isLoading={downloadLoading} onClick={onDownloadTemplate}>
                  Tải template
                </Button>
              </Flex>
              {!!error && <Text color={'red.300'}>{error}</Text>}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    Brand
                  </Th>
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    Port
                  </Th>
                  {/* <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                        Secure
                      </Th> */}
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    User Email
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    User Password
                  </Th>
                  <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                    Total Email Sent
                  </Th>
                  <Th minWidth="190px" borderColor={borderColor} color="gray.400">
                    Email Sent In Day
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Reach the Limit
                  </Th>
                  <Th minWidth="130px" borderColor={borderColor} color="gray.400">
                    Status
                  </Th>
                  <Th minWidth="170px" borderColor={borderColor} color="gray.400">
                    Backup Email
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((row, index, arr) => (
                  <SMTPRow
                    key={index}
                    setting={row}
                    isLast={index === arr.length - 1 ? true : false}
                    handelUpdateSetting={handelUpdateSetting}
                  />
                ))}
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="4">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Flex justifyContent="flex-end">
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { ...filter, pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              )}
            </Flex>
          </Stack>
        </CardBody>
      </Card>
      {isOpenAddModal && (
        <AddSMTPServerModal
          isOpen={isOpenAddModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
      <DeleteSMTPServerModal
        isOpen={isOpenDeleteModal}
        settingDetail={settingDetail}
        onClose={handelCloseAddModal}
        refetchData={handleRefetchData}
      />
      <ChangeStatusModal
        isOpen={isOpenChangeStatusModal}
        settingDetail={settingDetail}
        onClose={handelCloseAddModal}
        refetchData={handleRefetchData}
      />
      {isOpenAddEmailBackupModal && (
        <AddEmailBackupModal isOpen={isOpenAddEmailBackupModal} onClose={handelCloseAddModal} refetchData={handleRefetchData} />
      )}
      {isOpenSendEmailModal && (
        <SendEmailTestModal
          isOpen={isOpenSendEmailModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenChangeLimitModal && (
        <ChangeLimitModal
          isOpen={isOpenChangeLimitModal}
          settingDetail={settingDetail}
          onClose={handelCloseAddModal}
          refetchData={handleRefetchData}
        />
      )}
    </Flex>
  );
}

export default SMTPServer;
