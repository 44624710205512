import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Flex,
  FormLabel,
  Text,
  Box,
} from '@chakra-ui/react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { ModalType } from 'utils/constant';
import { TINY_API_KEY } from 'utils/constant';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';

const EmailResultModal = ({ isOpen, campaignDetail, onClose }) => {
  const cancelRef = React.useRef();

  return (
    <>
      <Modal
        isCentered
        size="2xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Detail);
        }}
        isOpen={isOpen}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="uppercase">Kết quả của Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{JSON.stringify(campaignDetail?.result)}</ModalBody>
          <ModalFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Detail);
              }}
            >
              Hủy
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailResultModal;
